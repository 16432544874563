// @ts-nocheck
import { Form as RemixForm } from '@remix-run/react'
import { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
export const Form: typeof RemixForm = forwardRef((props, ref) =>
  typeof window !== 'undefined' && window.__STORYBOOK_PREVIEW__ ? (
    <form {...props} ref={ref} />
  ) : (
    <RemixForm {...props} ref={ref} />
  ),
)
